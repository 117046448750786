import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import CustomTextPopup from "../../content/popups/CustomTextPopup";
import { useFormik } from "formik";
import { tenantSchema } from "../../widgets";
import StatusTitlePopup from "../../content/popups/multi-tenancy/StatusTitlePopup";
import { KTSVG } from "../../../helpers";
import "../styles/EditTenantModal.scss";
import {
  GetTenantActivityDTO,
  TenantActivityDTO,
  TenantDTO,
  UpdateTenantDTO,
} from "../../../../app/services/models/TenantModel";
import {
  getTenantActivities,
  getTenantConfigurations,
  updateTenant,
} from "../../../../app/services/TenantService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Props {
  tenant: TenantDTO;
  isOpen: boolean;
  toggleModal: () => void;
}

const EditTenantModal = ({ tenant, isOpen, toggleModal }: Props) => {
  const refreshBtnRef = useRef<HTMLButtonElement>(null);
  const [tenantState] = useState<TenantDTO>(tenant);
  const [tenantActivity, setTenantActivity] = useState<TenantActivityDTO>();
  const [tenantConfigurations, setTenantConfigurations] = useState<any | null>(
    null
  );

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    getFieldProps,
  } = useFormik({
    initialValues: {
      name: tenant?.name,
      code: tenant?.code,
      status: tenant?.isActive,
      dbInitialized: tenant?.dbInitialized,
      configurationsInitialized: tenantConfigurations
        ? tenantConfigurations.configurationsInitialized
        : "",
      companyName: tenant?.name,
      companyOrgNo: tenant?.code,
      dbName: "",
      dbPassword: "",
      dbServer: "",
      dbUsername: "",
    },
    validationSchema: tenantSchema,
    onSubmit: (values) => {
      updateTenantDetails(values);
    },
  });

  useEffect(() => {
    if (isOpen && !tenantConfigurations) {
      const fetchConfigurations = async () => {
        try {
          const configurations = await getTenantConfigurations(tenant.code);
          setTenantConfigurations(configurations.configurations);

          if (configurations.configurations) {
            const { dbName, dbPassword, dbServer, dbUsername } =
              configurations.configurations;

            handleChange({
              target: {
                name: "configurationsInitialized",
                value: true,
              },
            });
            handleChange({
              target: {
                name: "dbName",
                value: dbName || "",
              },
            });
            handleChange({
              target: {
                name: "dbPassword",
                value: dbPassword || "",
              },
            });
            handleChange({
              target: {
                name: "dbServer",
                value: dbServer || "",
              },
            });
            handleChange({
              target: {
                name: "dbUsername",
                value: dbUsername || "",
              },
            });
          }
        } catch (error) {
          console.error("Error fetching tenant configurations:", error);
        }
      };
      fetchConfigurations();
    }
  }, [isOpen, tenant, tenantConfigurations, handleChange]);

  const notify = (msg: string, status: boolean) => {
    if (status) {
      toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getTenantActivity = async (tenant: string) => {
    const tenantActivity: GetTenantActivityDTO = {
      tenantId: tenant,
      last: true,
    };
    await getTenantActivities(tenantActivity)
      .then((result) => {
        if (result.length !== 0) {
          setTenantActivity(result[0]);
        } else {
          setTenantActivity({
            id: "",
            tenantId: "",
            date: "",
            type: "",
            typeId: 0,
            message: "",
            status: "",
            statusId: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleConfigurations = () => {
    handleChange({
      target: {
        name: "configurationsInitialized",
        value: !values.configurationsInitialized,
      },
    });
  };

  const updateTenantDetails = async (e: any) => {
    let updatedTenant: UpdateTenantDTO = {
      id: tenantState.id,
      name: values.name,
      isActive: values.status,
      createDatabase: tenantState.dbInitialized ? false : values.dbInitialized,
      woConfigurationsInitialized: values.configurationsInitialized,
      updatedConfigurations: {
        companyName: values.name,
        companyOrgNo: values.code,
        dbName: values.dbName,
        dbServer: values.dbServer,
        dbUsername: values.dbUsername,
        dbPassword: values.dbPassword,
      },
    };
    await updateTenant(updatedTenant)
      .then((result) => {
        toggleModal();
        notify(JSON.stringify(result.message), true);
      })
      .catch((error) => {
        notify(JSON.stringify(error.message), false);
        console.log(error.message);
        throw error;
      });
  };

  const handleFetchLastStatus = () => {
    refreshBtnRef.current?.classList.add("rotate-clockwise");
    setTimeout(() => {
      refreshBtnRef.current?.classList.remove("rotate-clockwise");
      getTenantActivity(tenant.id);
    }, 3000);
  };

  useEffect(() => {
    getTenantActivity(tenant.id);
  }, [tenant]);

  const findTenantStatus = (tenantStatus: string) => {
    if (tenantStatus === "1") {
      return (
        <span
          className="badge badge-light-info fs-7 fw-bold d-flex pending"
          style={{ color: "#00A3FF" }}
        >
          <KTSVG
            path="/media/icons/duotune/general/pending-circle.svg"
            className="svg-icon-3 badge-light-info me-1 d-flex"
          />
          Pending
        </span>
      );
    } else if (tenantStatus === "2") {
      return (
        <span className="badge badge-light-success fs-7 fw-bold d-flex success">
          <KTSVG
            path="/media/icons/duotune/general/success-circle.svg"
            className="svg-icon-3 badge-light-success me-1 d-flex"
          />
          Success
        </span>
      );
    } else if (tenantStatus === "3") {
      return (
        <span className="badge badge-light-danger fs-7 fw-bold d-flex failed">
          <KTSVG
            path="/media/icons/duotune/general/failed-circle.svg"
            className="svg-icon-3 badge-light-danger me-1 d-flex"
          />
          Failed
        </span>
      );
    } else {
      return (
        <span
          className="badge badge-light-primary fs-7 fw-bold"
          style={{ WebkitTextFillColor: "#d2d0db" }}
        >
          N/A
        </span>
      );
    }
  };

  const renderInputField = (
    fieldName: keyof typeof values,
    label: string,
    placeholder: string,
    type = "text"
  ) => {
    return (
      <div className="form-group mb-7">
        <CustomTextPopup
          text={`Enter ${label.toLowerCase()} that is used for configurations.`}
          id={`${fieldName}LabelPopover`}
          placement="right"
        >
          <label className="mb-2">{label}</label>
        </CustomTextPopup>
        <input
          id={fieldName}
          name={fieldName}
          type={type}
          className="form-control form-control-solid mt-1"
          placeholder={`${placeholder}`}
          value={values[fieldName]}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched[fieldName] && errors[fieldName] && (
          <p className="text-danger">{errors[fieldName]}</p>
        )}
      </div>
    );
  };

  const showConfigurations =
    tenant?.name === values.companyName && tenant?.code === values.companyOrgNo;

  return (
    <>
      <Modal
        show={isOpen}
        onHide={toggleModal}
        dialogClassName="edit-tenant-modal"
      >
        <Modal.Header closeButton className="py-3">
          <h2>Edit Tenant</h2>
          <div className="d-flex status-block">
            <label>
              <StatusTitlePopup>
                <div>Last Status:</div>
              </StatusTitlePopup>
              {tenantActivity &&
                findTenantStatus(tenantActivity.statusId.toString())}
              <CustomTextPopup
                text="Press to refresh the database status"
                id="refreshStatusPopover"
                placement="bottom"
              >
                <button
                  ref={refreshBtnRef}
                  onClick={handleFetchLastStatus}
                  className="btn refresh-btn ms-5"
                >
                  <KTSVG
                    path="/media/icons/duotune/general/reload.svg"
                    className="svg-icon-3 svg-reload"
                  />
                </button>
              </CustomTextPopup>
            </label>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="card-body">
                {tenant && (
                  <div>
                    <div className="form-group mb-7">
                      <CustomTextPopup
                        text="Tenant name that is used to store the tenant in the system."
                        id="tenantNameLabelPopover"
                        placement="right"
                      >
                        <label>Name:*</label>
                      </CustomTextPopup>
                      <input
                        id="name"
                        type="text"
                        onChange={handleChange}
                        value={values.name}
                        onBlur={handleBlur}
                        className="form-control form-control-solid mt-1"
                        placeholder="Enter name"
                      />
                      {errors.name && touched.name && (
                        <p className="text-danger">{errors.name}</p>
                      )}
                    </div>
                    <div className="form-group mb-7">
                      <CustomTextPopup
                        text="Database name that is used to create the database."
                        id="tenantCodeLabelPopover"
                        placement="right"
                      >
                        <label>Code:*</label>
                      </CustomTextPopup>
                      <input
                        disabled
                        id="code"
                        type="text"
                        onChange={handleChange}
                        value={values.code}
                        className="form-control form-control-solid mt-1"
                        placeholder="Enter code"
                        onBlur={handleBlur}
                      />
                      {errors.code && touched.code && (
                        <p className="text-danger">{errors.code}</p>
                      )}
                    </div>
                    <div className="form-group mb-7">
                      <label className="form-check form-switch form-check-custom form-check-solid">
                        Create Database
                        <input
                          disabled={tenant.dbInitialized ? true : false}
                          id="dbInitialized"
                          type="checkbox"
                          checked={values.dbInitialized}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-check-input ms-20"
                        />
                        <span className="form-check-label fw-bold text-gray-400" />
                      </label>
                    </div>
                    <div className="form-group mb-7">
                      <label className="form-check form-switch form-check-custom form-check-solid">
                        Status
                        <input
                          id="status"
                          type="checkbox"
                          checked={values.status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-check-input ms-20"
                        />
                        <span className="form-check-label fw-bold text-gray-400" />
                      </label>
                    </div>
                  </div>
                )}
                {showConfigurations && (
                  <div>
                    <hr className="flex-grow-1 border-dark my-3 mb-6" />
                    <div className="section-header">
                      <h4>Web Office Configurations</h4>
                    </div>
                    {!tenantConfigurations && tenantConfigurations !== null && (
                      <div className="form-group mb-4 mt-4">
                        <label className="form-check form-switch form-check-custom form-check-solid">
                          Add Configurations
                          <input
                            id="configurationsInitialized"
                            type="checkbox"
                            checked={values.configurationsInitialized}
                            onChange={toggleConfigurations}
                            onBlur={handleBlur}
                            className="form-check-input ms-20"
                          />
                        </label>
                      </div>
                    )}
                    <div>
                      {values.configurationsInitialized && (
                        <div>
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-lg-6">
                                {renderInputField(
                                  "dbName",
                                  "Database Name",
                                  "database name"
                                )}
                                {renderInputField(
                                  "dbPassword",
                                  "Database Password",
                                  "●●●●●●●●",
                                  "password"
                                )}
                              </div>
                              <div className="col-lg-6">
                                {renderInputField(
                                  "dbServer",
                                  "Database Server",
                                  "database server"
                                )}
                                {renderInputField(
                                  "dbUsername",
                                  "Database Username",
                                  "database Username"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <button
            type="submit"
            className="btn btn-primary me-3 py-3"
            onClick={() => {
              handleSubmit();
            }}
          >
            Update
          </button>
          <button
            type="button"
            className="btn btn-light py-3"
            onClick={toggleModal}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditTenantModal;
